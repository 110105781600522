import Button from "../shared/forms/Button";
import Heading from "../shared/Heading";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import pick from "lodash.pick";
import { usePrivy } from "@privy-io/react-auth";
import { JOIN_BUNCH } from "../../gql/bunch/joinBunch";
import { MEDIA_QUERY_MD, MEDIA_QUERY_LG, MEDIA_QUERY_XL } from "../../utilities/constants/mediaQueries";
import { PRIVY_LOGIN } from "../../gql/user/loginUser";
import { handleCaughtError } from "../../utilities";
import { useAnalyticsContext } from "../state/AnalyticsProvider";
import { useAuthContext } from "../state/AuthProvider";
import { useBunchesContext } from "../state/BunchesProvider";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useRouter } from "next/router";
import { CHECK_IN_USER } from "../../gql/user/checkinUser";

const Container = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  height: 100dvh;
  padding: 1rem;
  width: 100%;
`;

const HeroContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  position: relative;
  width: 100%;

  @media (min-width: ${MEDIA_QUERY_LG}) {
    display: flex;
  }
`;

const HeroVideo = styled.video`
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 2;
`;

const Filter = styled.div`
  background: rgba(0, 0, 255, 0.75);
  border-radius: 1rem;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 99;
`;

const LoginContainer = styled.div`
  align-items: flex-start;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100dvh;
  padding: 1rem;
  width: 100%;
`;

const FooterContainer = styled.div`
  align-items: flex-start;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5rem;
  position: absolute;

  & > a {
    border-bottom: 1px solid transparent;
    border-radius: 20px;
    color: var(--color-gray-3);
    font-size: 0.8rem;
    margin-right: 0.5rem;
    padding: 0.25rem 1rem;
  }
`;

const FarcasterLink = styled(Link)`
  &:hover {
    background: #8a63d2;
    color: #fff;
  }
`;

const InstagramLink = styled(Link)`
  &:hover {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    color: #fff;
  }
`;

const BunchesLink = styled(Link)`
  &:hover {
    background: var(--color-primary);
    color: #fff;
  }
`;

const LoginContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 2rem;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  text-align: center;

  & > img {
    margin-top: 0.5rem;
  }
`;

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WelcomeHeading = styled(Heading)`
  color: var(--color-gray-1);
  font-size: 3.4em;
  font-weight: 700;
  text-transform: uppercase;
`;

const GreetingText = styled(Heading)`
  color: var(--color-gray-1);
  font-family: var(--font-family-headline);
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 3px;
  margin-top: -1rem;
  margin-left: 1px;
`;

const ProfileImage = styled(Image)`
  border-radius: 40px;
  margin: 0 0.6rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
`;

const SignInButton = styled(Button)<{ authenticated: boolean }>`
  border: 2px solid var(--color-white);
  border-bottom-right-radius: ${({ authenticated }) => (authenticated ? "0 !important" : "9999px")};
  border-top-right-radius: ${({ authenticated }) => (authenticated ? "0 !important" : "9999px")};
  font-family: var(--font-family-headline);
  font-size: 1.6em;
  font-weight: 700;
  min-width: 250px;
  max-width: 500px;
  outline: 2px solid transparent;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  width: 100%;
  z-index: 9999;

  & > span,
  & > img {
    display: none !important;
  }

  &:disabled {
    background: var(--color-gray-4);
    cursor: default;
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 1.6em;
    min-width: 400px;
    max-width: 500px;
    & > span,
    & > img {
      display: inline !important;
    }
  }

  @media (min-width: ${MEDIA_QUERY_LG}) {
    font-size: 1.8em;
    min-width: 350px;
    max-width: 500px;
    & > span,
    & > img {
      display: inline !important;
    }
  }

  @media (min-width: ${MEDIA_QUERY_XL}) {
    font-size: 1.8em;
    & > span,
    & > img {
      display: inline !important;
    }
  }

  @media (hover: hover) {
    &:hover:not(:disabled),
    &:focus {
      outline-color: var(--color-primary);
      border-color: var(--color-background);
      z-index: 0;
    }
  }
`;

const DisconnectButton = styled(Button)`
  align-self: center;
  font-size: 0.8em;
  font-weight: 600;
  background: var(--color-gray-1);
  border: 2px solid var(--color-white);
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  outline: 2px solid transparent;
  padding: 1.3rem;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  z-index: 9999;

  &:disabled {
    background: var(--color-gray-4);
    cursor: default;
  }

  @media (hover: hover) {
    &:hover:not(:disabled),
    &:focus {
      outline-color: var(--color-gray-1);
      border-color: var(--color-background);
      z-index: 0;
    }
  }
`;

export default function LoginWithPrivy() {
  const router = useRouter();
  const client = useApolloClient();

  const { ready, authenticated, login, logout, user } = usePrivy();
  const { pushTrackEvent } = useAnalyticsContext();
  const { currentUser, setCurrentUser, didLogout, token, setAuthToken, setDidLogout } = useAuthContext();
  const { joinBunch } = useBunchesContext();
  const [privyLogin, { data }] = useMutation(PRIVY_LOGIN);
  const [checkinUser] = useMutation(CHECK_IN_USER);
  const [fromParam, setFromParam] = useState(router.query.from);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const joinAndRouteToBunch = useCallback(
    async (bunchId: string) => {
      await joinBunch(bunchId);

      router.push(`/b/${bunchId}`);
    },
    [joinBunch, router],
  );

  const logoutViaPrivy = async () => {
    setAuthToken(null);
    setCurrentUser(null);
    setDidLogout(true);
    logout();
  };

  const resetViaPrivy = async () => {
    setDidLogout(false);
    login();
  };

  const loginViaPrivy = useCallback(async () => {
    setDidLogout(false);
    if (currentUser) {
      setAuthToken(currentUser);
    }
    if (!user && !currentUser) return;
    setLoading(true);

    let response = null;

    if (!currentUser) {
      const keysToInclude = ["id", "phone", "wallet", "farcaster"];
      const parsedPrivyUser = pick(user, keysToInclude);
      response = await privyLogin({ variables: { user: parsedPrivyUser } });
    }
    let userId =
      currentUser && currentUser.userId
        ? currentUser.userId
        : response && response.data && response.data.privyLogin && response.data.privyLogin.userId
          ? response.data.privyLogin.userId
          : null;

    try {
      if (userId) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        await checkinUser({
          variables: {
            userId: userId,
            deviceId: `web6212ab43-${userId}`,
            type: "web",
            isIos: false,
            timezone,
          },
        });
      }

      pushTrackEvent("[Login] Logged In");
      setLoading(false);
    } catch (error) {
      await handleCaughtError(error);
      setLoading(false);
    }
  }, [setLoading, pushTrackEvent, checkinUser, setDidLogout, privyLogin, setAuthToken, currentUser, user]);

  useEffect(() => {
    if (!user || !ready || !authenticated || didLogout) return;
    loginViaPrivy();
  }, [authenticated, currentUser, ready, user, didLogout, loginViaPrivy]);

  useEffect(() => {
    if (router.query.from) {
      setFromParam(router.query.from);
    }
  }, [router.query]);

  useEffect(() => {
    if (!data || !data.privyLogin) return;
    setLoading(true);

    if (fromParam) {
      client.query({
        query: JOIN_BUNCH,
        fetchPolicy: 'no-cache',
        variables: { bunchId: fromParam as string, userId: data.privyLogin.userId },
      });
    }
    setAuthToken(data.privyLogin);
    setLoading(false);
  }, [client, data, setAuthToken, fromParam]);

  useEffect(() => {
    if (!token || isRedirecting || didLogout) return;
    setLoading(true);

    if (fromParam) {
      setIsRedirecting(true);
      switch (fromParam) {
        case "new":
          router.push("/start");
          break;
        default:
          joinAndRouteToBunch(fromParam as string);
          break;
      }
    } else {
      setIsRedirecting(true);
      router.push("/discover");
    }
  }, [token, fromParam, router, joinAndRouteToBunch, isRedirecting, didLogout]);

  return (
    <Container>
      <HeroContainer>
        <Filter />
        <HeroVideo autoPlay muted loop>
          <source
            src="https://ik.imagekit.io/wrxztkausbq0/sports/Sport%20Stadium%204k%20Video_yNcPazaxq.mp4?updatedAt=1709874313940"
            type="video/mp4"
          />
        </HeroVideo>
      </HeroContainer>
      <LoginContainer>
        <LoginContentContainer>
          <LogoContainer>
            {
              <Image
                src="https://ik.imagekit.io/wrxztkausbq0/logo_transparent_pv9NMmnUQ.png?updatedAt=1709875588247"
                width={80}
                height={80}
                alt="Bunches"
                title="Bunches"
                priority
              />
            }
            <LogoText>
              <WelcomeHeading fontSize="2rem">BUNCHES</WelcomeHeading>
              <GreetingText>Chat Sports™</GreetingText>
            </LogoText>
          </LogoContainer>
          {!authenticated && !currentUser && !loading && (
            <SignInButton disabled={!ready} onClick={() => resetViaPrivy()}>
              Sign In
            </SignInButton>
          )}
          {(currentUser || authenticated) && !loading && (
            <Buttons>
              <SignInButton
                authenticated={currentUser || authenticated}
                disabled={!ready}
                onClick={() => loginViaPrivy()}
              >
                {currentUser && (
                  <>
                    Sign In<span>&nbsp;as</span>
                    <ProfileImage
                      src={currentUser.profilePictureUrl}
                      width={40}
                      height={40}
                      alt="Bunches"
                      title="Bunches"
                      priority
                    />
                    <span>{currentUser.username}</span>
                  </>
                )}
                {!currentUser && user.farcaster && (
                  <>
                    Sign In<span>&nbsp;as</span>
                    <ProfileImage
                      src={user.farcaster.pfp}
                      width={40}
                      height={40}
                      alt="Bunches"
                      title="Bunches"
                      priority
                    />
                    <span>{user.farcaster.username}</span>
                  </>
                )}
                {!currentUser && !user.farcaster && "Sign In"}
              </SignInButton>
              <DisconnectButton disabled={loading || !ready} onClick={() => logoutViaPrivy()}>
                Disconnect
              </DisconnectButton>
            </Buttons>
          )}
        </LoginContentContainer>
        <FooterContainer>
          <FarcasterLink target="_blank" href="https://warpcast.com/~/channel/bunches">
            /bunches
          </FarcasterLink>
          <InstagramLink target="_blank" href="https://instagram.com/onbunches">
            @onbunches
          </InstagramLink>
          <BunchesLink target="_blank" href="https://onbunches.com/terms-of-service">
            Terms of Service
          </BunchesLink>
          <BunchesLink target="_blank" href="https://onbunches.com/privacy-policy">
            Privacy Policy
          </BunchesLink>
        </FooterContainer>
      </LoginContainer>
    </Container>
  );
}
