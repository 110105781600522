import AuthLayout from "../../components/auth/AuthLayout";
import LoginWithPrivy from "../../components/auth/LoginWithPrivy";
import Head from "../../components/shared/header/Head";

export default function LoginPage() {
  return (
    <AuthLayout>
      <Head title="Bunches | Login" insightTracking />
      <LoginWithPrivy />
    </AuthLayout>
  );
}
